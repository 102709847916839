import styled from 'styled-components/macro';

/** Set up default heading elements */

export const H1 = styled.h1`
  margin: 0 0 0.5em;
  font-size: 7.2rem;
  line-height: 1.1;
  font-weight: 700;

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 6rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 4.8rem;
  }
`;

export const H2 = styled.h2`
  margin: 0 0 0.3em;
  font-size: 6.2rem;
  line-height: 1.3;
  font-weight: 600;

  &:first-child {
    margin-top: 0.5em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:only-child {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 5.4rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 4.2rem;
  }
`;

export const H3 = styled.h3`
  margin: 1.5em 0 1em;
  font-size: 2.4rem;
  line-height: 1.1;
  font-weight: bold;

  &:first-child {
    margin-top: 0.5em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 2.2rem;
  }
`;

export const H4 = styled.h4`
  margin: 0 0 0.1em;
  font-size: 2rem;
  line-height: 1.1;
  font-weight: normal;

  &:first-child {
    margin-top: 0.5em;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 1.8rem;
  }
`;
