import React from 'react';
import styled, { css } from 'styled-components/macro';
import { transitions } from 'polished';
import { Link } from 'react-router-dom';

export const ButtonStyles = css`
  appearance: none !important;
  background-color: ${props => props.theme.colors.green2} !important;
  border-radius: 27px !important;
  color: ${props => props.theme.colors.white} !important;
  font-weight: 800 !important;
  border: 1px solid ${props => props.theme.colors.green2} !important;
  padding: 0.4em 1.2em !important;
  font-size: 1.6rem !important;
  line-height: 1.9 !important;
  min-width: 14rem !important;
  margin: 4rem auto !important;
  display: block !important;
  cursor: pointer !important;
  text-align: center !important;
  font-variant-numeric: lining-nums !important;
  ${props =>
    transitions(
      ['border-color', 'background-color'],
      `${props.theme.animations.duration} ${props.theme.animations.easing}`
    )};

  &:hover,
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.green4} !important;
    border-color: ${props => props.theme.colors.green4} !important;
  }

  &:disabled {
    color: ${props => props.theme.colors.cloud} !important;
    background-color: ${props => props.theme.colors.green} !important;
    border-color: ${props => props.theme.colors.green} !important;
  }
`;

const StyledLinkBare = styled.a`
  text-decoration: none;
  color: inherit;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.green2};
  transition: ${props =>
    `color ${props.theme.animations.duration} ${props.theme.animations.easing}`};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.colors.green4};
  }
`;

export const StyledButton = styled.a`
  ${ButtonStyles};
  display: inline-block !important;
  text-decoration: none;
`;

const useLink = props => {
  /**
   * Determine if a link is external by constructing a URL object from the prop,
   * then comparing the hostname of the URL with the window's hostname. If the
   * `href` prop is a relative link like '/test', the window.location will be
   * used as a base. If it's an absolute URL like 'https://google.com', the base
   * will be ignored.
   *
   * Also add in a special check for the public/assets folder
   */
  const isExternal =
    (!!props.target && props.target === '_blank') ||
    new URL(props.href, window.location).hostname !== window.location.hostname;

  const attrs = isExternal
    ? /** Add extra attributes for external links */
      {
        href: props.href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : /** Internal Link components need the "to" prop instead of "href" */
      {
        to: props.href,
      };

  return [attrs, isExternal];
};

/** Links that look like buttons */
export const Button = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledButton
      {...props}
      {...attrs}
      as={isExternal ? undefined : Link}
      className={props.className}
    />
  );
};

/** An unstyled link, good for wrapping images */
export const LinkBare = props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLinkBare
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    >
      {props.children}
    </StyledLinkBare>
  );
};

/** Standard text links */
export default props => {
  const [attrs, isExternal] = useLink(props);
  return (
    <StyledLink
      {...props}
      {...attrs}
      /** If the link is internal, use a react-router Link */
      as={isExternal ? undefined : Link}
      /** Add the className so any other components can override the styling */
      className={props.className}
    />
  );
};
