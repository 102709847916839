import { createGlobalStyle } from 'styled-components/macro';
import { normalize } from 'polished';
import '../config/fontFace.css';

/**
 * This is a one-time-use method to add global styles.
 *
 * Use for things like body and html styles, and anything that else can't fit as
 * a react component
 */
export default createGlobalStyle`
  ${normalize()}

  * {
    box-sizing: border-box;
  }

  html {
    /** Allows us to easily translate px to rem, e.g. 1.8rem = 18px */
    font-size: 62.5%;

    @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
      font-size: 56.25%;
    }

    ${
      '' /* @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
      font-size: 50%;
    } */
    }
  }

  body {
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.6;
    overflow-x: hidden;

    color: ${props => props.theme.colors.black};
    font-family: ${props => props.theme.fonts.sansSerif};
    font-variant-numeric: lining-nums;
  }

  code {
    background: #fdf9e0;
    font-size: 0.8em;
    display: inline-block;
    padding: 0 0.2em;
  }
`;
