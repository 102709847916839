import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Image from './Image';

const MediaTextWrapper = styled.div``;

const Body = styled.div`
  margin: 0;
  font-family: Brando Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.5;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0 0 1em;
  width: ${props => !!props.mediaWidth && props.mediaWidth};
  max-width: 100%;
  min-height: 6.2em;
  max-height: 9em;

  svg,
  ${Image} {
    max-width: 100%;
    height: auto;
    max-height: ${props => !!props.mediaMaxHeight && props.mediaMaxHeight};
  }
`;

const Media = styled(Image)`
  /* width: ${props => !!props.mediaWidth && props.mediaWidth}; */
`;

export default props => {
  const { fields } = props;
  const { body, media, mediaWidth = null, mediaMaxHeight = null } = fields;

  const [svgCode, setSvg] = useState(null);

  useEffect(() => {
    if (
      !!svgCode ||
      (!!media &&
        !!media.fields &&
        !!media.fields.file &&
        !!media.fields.file.contentType &&
        !media.fields.file.contentType === 'image/svg+xml')
    ) {
      return;
    }

    async function fetchData() {
      await fetch(media.fields.file.url)
        .then(response => response.text())
        .then(body => setSvg(body));
    }
    media.fields.file.url && fetchData();
  }, [media, svgCode]);

  const alt = media.fields && (media.fields.description || media.fields.title);

  return (
    <MediaTextWrapper>
      {!!media && !!svgCode ? (
        <ImageWrapper
          mediaWidth={mediaWidth}
          mediaMaxHeight={mediaMaxHeight}
          dangerouslySetInnerHTML={{ __html: svgCode }}
        />
      ) : (
        !!media &&
        media.fields &&
        media.fields.file && (
          <ImageWrapper mediaWidth={mediaWidth} mediaMaxHeight={mediaMaxHeight}>
            <Media src={media.fields.file.url} alt={alt} />
          </ImageWrapper>
        )
      )}
      {body && <Body>{body}</Body>}
    </MediaTextWrapper>
  );
};
