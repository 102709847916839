import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';
import { useSpring, animated } from 'react-spring';
import { countDecimals } from './Counter';

const Number = styled(animated.tspan)`
  color: ${props => props.theme.colors.black};
`;

const Unit = styled.tspan``;

const Radial = props => {
  const [shouldAnimate, setAnimate] = useState(false);

  const { trackWidth, barWidth, trackColor, barColor, data } = props;

  const PI = 22 / 7;
  /** The base number for the svg */
  const radius = 50;
  const diameter = radius * 2;
  /** Round it to 2 decimal places */
  const circumference = Math.round(PI * diameter * 100) / 100;
  /** Figure out which stroke we need to plan the dimensions for */
  const thickestStroke = Math.max(trackWidth, barWidth);
  const radiusWithStroke = radius + thickestStroke / 2;
  const diameterWithStroke = radiusWithStroke * 2;

  /** react-spring throws an error if 0 is a number instead of string */
  const dataStart = '0';

  const springProps = useSpring({
    /**
     * Setting circumference as the stroke-dashoffset will make the bar
     * completely hidden
     */
    from: { number: dataStart },
    /**
     * There's no "pause" in react-spring currently, so set the start value as
     * the end too so it doesn't actually change
     */
    to: { number: shouldAnimate ? data : dataStart },
    config: { mass: 1, tension: 100, resistance: 20 },
  });

  /**
   * When stroke-dashoffset is 0, the bar displays at full length. To get the
   * final offset to display to the user, convert the number prop to a
   * percentage and get the difference from the total circumference. The
   * difference from the circumference and that calculated number is the
   * offset that displays correctly
   */
  const calculateOffset = value =>
    circumference - (value / 100) * circumference;

  /** Format the number in the middle of the radial */
  const numberOfDecimals = countDecimals(data);
  const formatNumber = value => (+value).toFixed(numberOfDecimals);

  return (
    <Waypoint topOffset="0" bottomOffset="30%" onEnter={() => setAnimate(true)}>
      {/** This is a normal dom element so that Waypoints can attach to it */}
      <svg
        style={{
          overflow: 'visible',
          width: '100%',
          height: 'auto',
        }}
        width={`${diameterWithStroke}px`}
        height={`${diameterWithStroke}px`}
        viewBox={`0 0 ${diameterWithStroke} ${diameterWithStroke}`}
      >
        {/** Track */}
        <circle
          cx={radiusWithStroke}
          cy={radiusWithStroke}
          r={radius}
          stroke={trackColor}
          strokeWidth={trackWidth}
          fill="none"
        />
        {/** Bar */}
        <animated.circle
          fill="none"
          r={radius}
          stroke={barColor}
          // strokeLinecap="round"
          strokeMiterlimit="10"
          strokeWidth={barWidth}
          strokeDasharray={circumference}
          strokeDashoffset={springProps.number.interpolate(calculateOffset)}
          transform={`matrix(0 -1 1 0 ${radiusWithStroke} ${radiusWithStroke})`}
        />
        <g
          transform={`translate(${radiusWithStroke},${radiusWithStroke})`}
          lineheight="1"
        >
          <text
            dy={32 / 3}
            fontSize="36"
            lineheight="1"
            textAnchor="middle"
            fontWeight="bold"
          >
            <Number>{springProps.number.interpolate(formatNumber)}</Number>
            <Unit>%</Unit>
          </text>
        </g>
      </svg>
    </Waypoint>
  );
};

Radial.propTypes = {
  /** This is a string so we don't run into some number animation issues */
  data: PropTypes.string,
  barWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  barColor: PropTypes.string,
  trackColor: PropTypes.string,
};

Radial.defaultProps = {
  data: '0',
  barWidth: 14,
  trackWidth: 3,
  barColor: '#000',
  trackColor: '#ddd',
};

export default Radial;
