import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const IntroNumbersGraphicBlock = props => {
  return (
    <svg
      id="b3a8d84c-9ca8-4ff7-98cd-bc021173ef6c"
      dataname="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1281.66"
      height="357.86"
      viewBox="0 0 1281.66 357.86"
      className={props.className}
    >
      <defs>
        <linearGradient
          id="af20e7e1-c20a-4b99-a59b-1243f4846776"
          x1="140.18"
          y1="345.97"
          x2="40.52"
          y2="166.89"
          gradientTransform="matrix(1, 0, 0, -1, 0, 358)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#0072ce" />
          <stop offset="0.55" stopColor="#0090d9" />
          <stop offset="1" stopColor="#00a3e0" />
        </linearGradient>
        <linearGradient
          id="ac0724ec-8df5-45a9-831c-fdb8491a0b35"
          x1="381.8"
          y1="348.03"
          x2="319.72"
          y2="154.49"
          gradientTransform="matrix(1, 0, 0, -1, 0, 358)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#84bd00" />
          <stop offset="0.2" stopColor="#8ac000" />
          <stop offset="0.46" stopColor="#9ac700" />
          <stop offset="0.76" stopColor="#b4d300" />
          <stop offset="1" stopColor="#d0df00" />
        </linearGradient>
        <linearGradient
          id="ed20d480-0549-46b7-b580-8545f7006bca"
          x1="627.75"
          y1="342.79"
          x2="572.98"
          y2="123.69"
          gradientTransform="matrix(1, 0, 0, -1, 0, 358)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#59cbe8" />
          <stop offset="0.27" stopColor="#67d2ec" />
          <stop offset="0.79" stopColor="#8de6f7" />
          <stop offset="1" stopColor="#9feffc" />
        </linearGradient>
        <linearGradient
          id="bd3651ad-ac2f-4ce4-b30c-135170a7dc51"
          x1="903.44"
          y1="363.44"
          x2="870.57"
          y2="151.64"
          gradientTransform="matrix(1, 0, 0, -1, 0, 358)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#1c6133" />
          <stop offset="0.45" stopColor="#117635" />
          <stop offset="1" stopColor="#009639" />
        </linearGradient>
        <linearGradient
          id="b5552427-d2db-4265-b7d2-debe0e0615bd"
          x1="1161.61"
          y1="361.31"
          x2="1132.4"
          y2="167.78"
          gradientTransform="matrix(1, 0, 0, -1, 0, 358)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00a3e0" />
          <stop offset="0.19" stopColor="#0ba8e1" />
          <stop offset="0.53" stopColor="#27b4e3" />
          <stop offset="0.96" stopColor="#55c9e8" />
          <stop offset="1" stopColor="#59cbe8" />
        </linearGradient>
      </defs>
      <g
        id="b83c0efd-459b-4160-9fc3-7f45a2891583"
        className="numbers"
        dataname="numbers"
      >
        <path
          d="M36.39,136.67H71.75V50.86L36.62,57V22.1l49.51-12H117V136.67h33v33.71H36.39Z"
          fill="url(#af20e7e1-c20a-4b99-a59b-1243f4846776)"
        />

        <path
          d="M293.38,143.69c26.46-20.37,65.33-57.84,65.33-79.14,0-14.52-8.2-21.31-26.23-21.31-13.34,0-25.05,3.51-30.9,6.56h-3V15.19c8.43-3.74,24.35-6.32,43.55-6.32,40.74,0,64.39,15.69,64.39,48,0,31.37-22,55.72-53.15,79.61h58.5v34H293.38Z"
          fill="url(#ac0724ec-8df5-45a9-831c-fdb8491a0b35)"
        />

        <path
          d="M555.23,167.8v-34H558c6.09,1.64,22.71,2.81,30.91,2.81,23.65,0,34.18-6.09,34.18-16.86,0-12.17-11.24-15.45-33.24-15.45H575.6V71.1h13.58c19.67,0,29-4.68,29-17.09,0-8.43-8-13.11-23.65-13.11-13.35,0-28.1,3.51-34,6.55h-3V12.8c8.43-3.75,27.4-6.32,46.6-6.32,41,0,61.81,13.81,61.81,36.53,0,23.88-17.79,35.58-35.35,38.86v.94c22.51,2.11,40.28,15.22,40.28,36.76,0,33.25-28.8,50.81-74.46,50.81C583.56,170.38,562.72,169.44,555.23,167.8Z"
          fill="url(#ed20d480-0549-46b7-b580-8545f7006bca)"
        />

        <path
          d="M814.27,112.31l66-101.12h48.94v98.34h22.24v32.31H929.24v28.57H885.92v-28.6H814.27Zm71.65-2.81v-55l-34.18,55Z"
          fill="url(#bd3651ad-ac2f-4ce4-b30c-135170a7dc51)"
        />

        <path
          d="M1094.87,167.8v-34h2.81c5.15,1.64,17.8,2.81,23.89,2.81,24.81,0,36.52-4.21,36.52-20.13,0-12.18-12.64-19-36.06-19-8.42,0-20.83.47-24.11.94V8.82h102.79v34h-61.35V63.38c42.15,1.17,65.79,17.79,65.79,48.7,0,34.88-20.83,58.3-77.73,58.3A272.81,272.81,0,0,1,1094.87,167.8Z"
          fill="url(#b5552427-d2db-4265-b7d2-debe0e0615bd)"
        />
      </g>
      <g
        id="ba7d822d-781d-4da0-a605-9cdd8d28d8d9"
        className="text"
        dataname="Layer 1"
      >
        <g>
          <path
            d="M37.65,227.41v-3.69H38a14.16,14.16,0,0,0,5,.75c3,0,4.11-.87,4.11-2.19s-.75-2-4-2.85c-3.6-1-5.64-2.37-5.64-6.27s3-5.94,8.43-5.94a14.47,14.47,0,0,1,5.46.81v3.69H51a14.57,14.57,0,0,0-5.07-.78c-2.73,0-3.66.75-3.66,1.83s.75,1.74,3.33,2.49c4.44,1.2,6.27,2.67,6.27,6.36,0,4.08-2.91,6.6-8.49,6.6A16.91,16.91,0,0,1,37.65,227.41Z"
            fill="#2d2926"
          />
          <path
            d="M54.66,223.84V213.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08v-10H68.7v14.49H64.14v-1.5H64a6.53,6.53,0,0,1-4.65,1.77C56.31,228.19,54.66,226.72,54.66,223.84Z"
            fill="#2d2926"
          />
          <path
            d="M72.24,213.43h4.63v1.76H77a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.27,5.27,0,0,0-4.84,1.42v9.42H72.24Z"
            fill="#2d2926"
          />
          <path
            d="M82.55,213.43h4.81l2,6.24c.22.63.81,2.76,1,3.57h.07c.15-.81.72-2.94.92-3.57l2-6.24h4.52l-5,14.49H87.78Z"
            fill="#2d2926"
          />
          <path
            d="M99.45,208.57a2.61,2.61,0,1,1,2.78,2.43h-.18a2.29,2.29,0,0,1-2.58-2A2.33,2.33,0,0,1,99.45,208.57Zm.29,4.86h4.65v14.49H99.74Z"
            fill="#2d2926"
          />
          <path
            d="M106.13,213.43h4.8l2,6.24c.21.63.82,2.76,1,3.57H114c.15-.81.72-2.94.93-3.57l2-6.24h4.47l-5,14.49h-5Z"
            fill="#2d2926"
          />
          <path
            d="M123,208.57a2.62,2.62,0,1,1,2.8,2.43h-.19C124,211,123,210.19,123,208.57Zm.3,4.86H128v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M131.51,213.43H136v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M147.59,231.43c0-1.5,1.56-2.79,3.21-3.24V228a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.41,4.41,0,0,1,2.07,3.9c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C149.78,235,147.59,233.68,147.59,231.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.79,0,4.38-.65,4.38-1.82Zm-2-12.18a2.29,2.29,0,0,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52a2.18,2.18,0,0,0,2.33-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M172.61,223.87v-7h-2.19v-3.42h2.19v-3.87h4.65v3.87h3.66v3.42h-3.66v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C174.17,228.19,172.61,226.9,172.61,223.87Z"
            fill="#2d2926"
          />
          <path
            d="M183.41,206h4.65v8.85h.12a6.29,6.29,0,0,1,4.53-1.74c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M214.31,220.06q0,.94-.12,1.86H205c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C212.24,213.13,214.31,216.13,214.31,220.06Zm-9.42-.69h5c-.09-2-.75-3-2.22-3s-2.52.63-2.78,3Z"
            fill="#2d2926"
          />
          <path
            d="M38.16,262.75V245h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C39.39,267.1,38.16,265.72,38.16,262.75Z"
            fill="#2d2926"
          />
          <path
            d="M55.65,265.54h-.12a5.49,5.49,0,0,1-4.24,1.65c-3,0-4.76-1.83-4.76-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.64-2.22-3.09-2.22a11.73,11.73,0,0,0-4.32.66h-.33v-3.24a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.4ZM53,264.25a3,3,0,0,0,2.43-1.2v-2.28h-1.5c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7A1,1,0,0,0,53,264.25Z"
            fill="#2d2926"
          />
          <path
            d="M63.51,262.84V252.43h4.64v9c0,1.56.7,2.13,2.07,2.13a4,4,0,0,0,2.65-1.05V252.43h4.65v14.49H73v-1.5h-.11a6.56,6.56,0,0,1-4.66,1.8C65.15,267.19,63.51,265.72,63.51,262.84Z"
            fill="#2d2926"
          />
          <path
            d="M81.08,252.43h4.54v1.5h.11a6.63,6.63,0,0,1,4.65-1.8c3.07,0,4.71,1.47,4.71,4.38v10.41H90.45v-9c0-1.56-.7-2.13-2.08-2.13a4.15,4.15,0,0,0-2.64,1V267H81.08Z"
            fill="#2d2926"
          />
          <path
            d="M105.77,252.13a8.36,8.36,0,0,1,4,.78v3.28h-.33a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C97.7,255.1,100.55,252.13,105.77,252.13Z"
            fill="#2d2926"
          />
          <path
            d="M112.58,245h4.65v8.85h.12a6.31,6.31,0,0,1,4.52-1.74c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.64v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V267h-4.65Z"
            fill="#2d2926"
          />
        </g>
        <g>
          <path
            d="M295.48,207.52h8.19c5.07,0,7.2,2.22,7.2,5.28a4.81,4.81,0,0,1-3.69,4.41c2.73.63,4.68,2.34,4.68,4.71,0,3.84-2.46,6-8.22,6h-8.16Zm7.65,8.4c1.89,0,2.85-.93,2.85-2.46s-.87-2.46-3-2.46h-2.67v4.92Zm0,8.52c2.55,0,3.75-.66,3.75-2.64,0-1.71-1.11-2.55-3.48-2.55h-3.06v5.19Z"
            fill="#2d2926"
          />
          <path
            d="M314.41,223.84V213.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08v-10h4.65v14.49h-4.58v-1.5h-.12a6.52,6.52,0,0,1-4.65,1.8C316.06,228.19,314.41,226.72,314.41,223.84Z"
            fill="#2d2926"
          />
          <path
            d="M331.69,208.57a2.62,2.62,0,1,1,2.8,2.43h-.19C332.68,211,331.69,210.19,331.69,208.57Zm.3,4.86h4.65v14.49H332Z"
            fill="#2d2926"
          />
          <path
            d="M340.18,223.75V206h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C341.41,228.1,340.18,226.72,340.18,223.75Z"
            fill="#2d2926"
          />
          <path
            d="M358.3,226.6h-.12a5,5,0,0,1-3.93,1.62c-3.54,0-5.94-2.79-5.94-7.2,0-5,3.18-7.86,7.92-7.86a7.48,7.48,0,0,1,2,.27V206h4.64v21.9h-4.56Zm-2.46-2a3.23,3.23,0,0,0,2.37-1v-7a3.84,3.84,0,0,0-1.5-.24c-2.16,0-3.54,1.41-3.54,4.41,0,2.65,1.11,3.85,2.7,3.85Z"
            fill="#2d2926"
          />
          <path
            d="M366.07,208.57a2.62,2.62,0,1,1,2.8,2.43h-.19C367.06,211,366.07,210.19,366.07,208.57Zm.3,4.86H371v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M374.56,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M390.64,231.43c0-1.5,1.56-2.79,3.21-3.24V228a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1.05v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.39,4.39,0,0,1,2.08,3.89c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C392.87,235,390.64,233.68,390.64,231.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.79,0,4.38-.65,4.38-1.82Zm-1.95-12.18a2.29,2.29,0,1,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52s2.28-.83,2.28-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M423.16,226.54H423a5.46,5.46,0,0,1-4.23,1.68c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66h-.33V214a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.44Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28h-1.5c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7,1,1,0,0,0,.24,0Z"
            fill="#2d2926"
          />
          <path
            d="M431.08,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M308.53,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C306.46,252.13,308.53,255.13,308.53,259.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3S299.35,256,299.11,258.37Z"
            fill="#2d2926"
          />
          <path
            d="M314.71,259.48l-5.16-7.05h5.32l2.91,4.2,2.91-4.2h4.83l-5.25,7.08,5.43,7.41h-5.28l-3.21-4.56-3.15,4.56h-4.83Z"
            fill="#2d2926"
          />
          <path
            d="M334.45,252.13a8.36,8.36,0,0,1,4,.78v3.28h-.33a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C326.38,255.1,329.23,252.13,334.45,252.13Z"
            fill="#2d2926"
          />
          <path
            d="M354.43,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C352.36,252.13,354.43,255.13,354.43,259.06Zm-9.42-.69H350c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M357.1,252.43h4.53v1.29h.12a5.13,5.13,0,0,1,4-1.59c3.54,0,5.91,2.79,5.91,7.17,0,5-3.18,7.89-7.92,7.89a7.57,7.57,0,0,1-1.95-.26v6.45h-4.65Zm7,3.3a3,3,0,0,0-2.37,1v7a4.09,4.09,0,0,0,1.47.24c2.16,0,3.57-1.41,3.57-4.41,0-2.63-1.12-3.83-2.65-3.83Z"
            fill="#2d2926"
          />
          <path
            d="M375,262.87v-7h-2.19v-3.42H375v-3.87h4.65v3.87h3.66v3.42H379.6v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C376.51,267.19,375,265.9,375,262.87Z"
            fill="#2d2926"
          />
          <path
            d="M385.45,247.57a2.62,2.62,0,1,1,2.8,2.43h-.19C386.44,250,385.45,249.19,385.45,247.57Zm.3,4.86h4.65v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M393,259.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S393,264.7,393,259.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26,2.7-1.38,2.7-4.35Z"
            fill="#2d2926"
          />
          <path
            d="M410.87,252.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V267h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M436.57,265.54h-.12a5.46,5.46,0,0,1-4.23,1.68c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66h-.33V253a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.4Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28h-1.49c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7Z"
            fill="#2d2926"
          />
          <path
            d="M444.49,262.75V245h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C445.72,267.1,444.49,265.72,444.49,262.75Z"
            fill="#2d2926"
          />
          <path
            d="M302.32,291.13a8.36,8.36,0,0,1,4,.78v3.28H306a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C294.25,294.1,297.1,291.13,302.32,291.13Z"
            fill="#2d2926"
          />
          <path
            d="M309.07,301.84V291.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08V291.43h4.65v14.49h-4.56v-1.5h-.12a6.52,6.52,0,0,1-4.65,1.8C310.72,306.19,309.07,304.72,309.07,301.84Z"
            fill="#2d2926"
          />
          <path
            d="M326,305.38v-3.33h.3A12.36,12.36,0,0,0,331,303c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,326,305.38Z"
            fill="#2d2926"
          />
          <path
            d="M341.35,301.87v-7h-2.19v-3.42h2.19v-3.87H346v3.87h3.66v3.42H346v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C342.87,306.19,341.35,304.9,341.35,301.87Z"
            fill="#2d2926"
          />
          <path
            d="M351.13,298.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S351.13,303.7,351.13,298.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26,2.7-1.38,2.7-4.35Z"
            fill="#2d2926"
          />
          <path
            d="M369,291.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c2.19,0,3.63.75,4.29,2.25a6.65,6.65,0,0,1,5.1-2.25c3.06,0,4.71,1.47,4.71,4.38v10.41H387.7v-9c0-1.56-.69-2.13-2.1-2.13a4.26,4.26,0,0,0-2.64,1V306h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V306H369Z"
            fill="#2d2926"
          />
          <path
            d="M409.21,298.06q0,.94-.12,1.86h-9.22c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C407.14,291.13,409.21,294.13,409.21,298.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M411.87,291.43h4.65v1.76h.09a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42h-4.63Z"
            fill="#2d2926"
          />
          <path
            d="M308.53,337.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C306.46,330.13,308.53,333.13,308.53,337.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3S299.35,334,299.11,336.37Z"
            fill="#2d2926"
          />
          <path
            d="M314.71,337.48l-5.16-7.05h5.32l2.91,4.2,2.91-4.2h4.83l-5.25,7.08,5.43,7.41h-5.28l-3.21-4.56-3.15,4.56h-4.83Z"
            fill="#2d2926"
          />
          <path
            d="M327.46,330.43H332v1.29h.12a5.13,5.13,0,0,1,4-1.59c3.54,0,5.91,2.79,5.91,7.17,0,5-3.18,7.89-7.92,7.89a7.57,7.57,0,0,1-2-.26v6.45H327.5Zm7,3.3a3,3,0,0,0-2.37,1v7a4.09,4.09,0,0,0,1.47.24c2.16,0,3.57-1.41,3.57-4.41,0-2.63-1.12-3.83-2.65-3.83Z"
            fill="#2d2926"
          />
          <path
            d="M358,337.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C355.93,330.13,358,333.13,358,337.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3S348.87,334,348.58,336.37Z"
            fill="#2d2926"
          />
          <path
            d="M360.67,330.43h4.65v1.76h.09a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42h-4.63Z"
            fill="#2d2926"
          />
          <path
            d="M372.4,325.57a2.62,2.62,0,1,1,2.8,2.43H375C373.39,328,372.4,327.19,372.4,325.57Zm.3,4.86h4.65v14.49H372.7Z"
            fill="#2d2926"
          />
          <path
            d="M394.27,337.06q0,.94-.12,1.86h-9.28c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C392.2,330.13,394.27,333.13,394.27,337.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3s-2.49.63-2.71,3Z"
            fill="#2d2926"
          />
          <path
            d="M396.94,330.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41H406.3v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V345h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M421.63,330.13a8.36,8.36,0,0,1,4,.78v3.28h-.33a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C413.56,333.1,416.41,330.13,421.63,330.13Z"
            fill="#2d2926"
          />
          <path
            d="M441.61,337.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C439.54,330.13,441.61,333.13,441.61,337.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
        </g>
        <g>
          <path
            d="M557.56,207.52h6.87c6.09,0,11.28,2.64,11.28,9.69s-4.74,10.71-11.55,10.71h-6.6Zm6.24,16.67c4.08,0,6.78-1.65,6.78-6.72s-2.71-6.28-6.71-6.28h-1.41v13Z"
            fill="#2d2926"
          />
          <path
            d="M592,220.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C589.87,213.13,592,216.13,592,220.06Zm-9.42-.69h5c-.09-2-.75-3-2.22-3S582.79,217,582.55,219.37Z"
            fill="#2d2926"
          />
          <path
            d="M592.72,213.43h4.8l2,6.24c.21.63.81,2.76,1,3.57h.06c.15-.81.72-2.94.93-3.57l2-6.24H608l-5,14.49H597.9Z"
            fill="#2d2926"
          />
          <path
            d="M623.14,220.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C621.07,213.13,623.14,216.13,623.14,220.06Zm-9.42-.69h4.95c-.09-2-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M625.81,223.75V206h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C627,228.1,625.81,226.72,625.81,223.75Z"
            fill="#2d2926"
          />
          <path
            d="M633.94,220.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S633.94,225.7,633.94,220.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26S644.26,223.6,644.26,220.63Z"
            fill="#2d2926"
          />
          <path
            d="M651.76,213.43h4.53v1.29h.12a5.13,5.13,0,0,1,4-1.59c3.54,0,5.91,2.79,5.91,7.17,0,5-3.18,7.89-7.92,7.89a7.48,7.48,0,0,1-2-.27v6.45h-4.65Zm7,3.3a3,3,0,0,0-2.37,1v7a4.09,4.09,0,0,0,1.47.24c2.16,0,3.57-1.41,3.57-4.41,0-2.63-1.12-3.83-2.65-3.83Z"
            fill="#2d2926"
          />
          <path
            d="M668.62,208.57a2.62,2.62,0,1,1,2.8,2.43h-.19C669.61,211,668.62,210.19,668.62,208.57Zm.3,4.86h4.65v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M677.11,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M693.19,231.43c0-1.5,1.56-2.79,3.21-3.24V228a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.41,4.41,0,0,1,2.07,3.9c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C695.38,235,693.19,233.68,693.19,231.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.79,0,4.39-.65,4.39-1.82Zm-2-12.18a2.29,2.29,0,1,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52s2.29-.83,2.29-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M557.92,262.87v-7h-2.19v-3.42h2.19v-3.87h4.65v3.87h3.66v3.42h-3.66v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C559.48,267.19,557.92,265.9,557.92,262.87Z"
            fill="#2d2926"
          />
          <path
            d="M568.72,252.43h4.65v1.76h.09a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42H568.7Z"
            fill="#2d2926"
          />
          <path
            d="M580.69,262.84V252.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08V252.43h4.65v14.49h-4.56v-1.5h-.12a6.52,6.52,0,0,1-4.65,1.8C582.34,267.19,580.69,265.72,580.69,262.84Z"
            fill="#2d2926"
          />
          <path
            d="M611.65,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C609.58,252.13,611.65,255.13,611.65,259.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M621.13,262.75V245h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C622.36,267.1,621.13,265.72,621.13,262.75Z"
            fill="#2d2926"
          />
          <path
            d="M643.54,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C641.47,252.13,643.54,255.13,643.54,259.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3S634.36,256,634.12,258.37Z"
            fill="#2d2926"
          />
          <path
            d="M654.25,265.54h-.12a5.53,5.53,0,0,1-4.26,1.65c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66h-.33v-3.24a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.44Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28h-1.5c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7A1,1,0,0,0,651.61,264.25Z"
            fill="#2d2926"
          />
          <path
            d="M671.26,265.6h-.12a5,5,0,0,1-3.93,1.62c-3.54,0-5.94-2.79-5.94-7.2,0-5,3.18-7.86,7.92-7.86a7.48,7.48,0,0,1,2,.27V245h4.68v21.9h-4.56Zm-2.46-2a3.23,3.23,0,0,0,2.37-1v-7a3.84,3.84,0,0,0-1.5-.24c-2.16,0-3.54,1.41-3.54,4.41,0,2.65,1.11,3.85,2.67,3.85Z"
            fill="#2d2926"
          />
          <path
            d="M692.71,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C690.64,252.13,692.71,255.13,692.71,259.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3s-2.54.63-2.78,3Z"
            fill="#2d2926"
          />
          <path
            d="M695.38,252.43H700v1.76h.09a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M706.72,266.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22H718a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,706.72,266.38Z"
            fill="#2d2926"
          />
        </g>
        <g>
          <path
            d="M812.37,207.52h8.34c4.71,0,7.26,2.25,7.26,6.15a5.89,5.89,0,0,1-4,5.61l5.55,8.64h-5.46l-4.56-7.59h-2.25v7.59h-4.88Zm7,9.27c2.49,0,3.54-1.08,3.54-2.94s-1.17-2.7-3.12-2.7h-2.55v5.64Z"
            fill="#2d2926"
          />
          <path
            d="M844.56,220.06q0,.94-.12,1.86H835.2c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C842.49,213.13,844.56,216.13,844.56,220.06Zm-9.42-.69h5c-.09-2-.75-3-2.22-3S835.38,217,835.14,219.37Z"
            fill="#2d2926"
          />
          <path
            d="M848.16,216.85h-2.43v-3.42h2.43v-1.77c0-4,1.68-5.85,5.82-5.85a8.73,8.73,0,0,1,2.28.27v3.24H856a6,6,0,0,0-1.2-.12c-1.26,0-2,.57-2,2.28v2h9.69V228h-4.63V216.85h-5v11.07h-4.65Zm9.39-8.28a2.3,2.3,0,0,1,2.11-2.47,2.79,2.79,0,0,1,.5,0,2.34,2.34,0,0,1,2.62,2,2.2,2.2,0,0,1,0,.44c0,1.62-1,2.43-2.64,2.43S857.55,210.19,857.55,208.57Z"
            fill="#2d2926"
          />
          <path
            d="M866,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228H866Z"
            fill="#2d2926"
          />
          <path
            d="M883.2,208.57A2.62,2.62,0,1,1,886,211h-.19C884.19,211,883.2,210.19,883.2,208.57Zm.3,4.86h4.65v14.49H883.5Z"
            fill="#2d2926"
          />
          <path
            d="M891.69,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M907.77,231.43c0-1.5,1.56-2.79,3.21-3.24V228a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1.05v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.41,4.41,0,0,1,2.07,3.9c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C910,235,907.77,233.68,907.77,231.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.81,0,4.38-.65,4.38-1.82Zm-2-12.18a2.29,2.29,0,1,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52a2.18,2.18,0,0,0,2.33-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M812,252.43h4.53v1.29h.12a5.13,5.13,0,0,1,4-1.59c3.54,0,5.91,2.79,5.91,7.17,0,5-3.18,7.89-7.92,7.89a7.57,7.57,0,0,1-1.95-.26v6.45h-4.65Zm7,3.3a3,3,0,0,0-2.37,1v7a4.09,4.09,0,0,0,1.47.24c2.16,0,3.57-1.41,3.57-4.41,0-2.63-1.12-3.83-2.65-3.83Z"
            fill="#2d2926"
          />
          <path
            d="M829.2,252.43h4.67v1.76H834a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42H829.2Z"
            fill="#2d2926"
          />
          <path
            d="M840.18,259.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S840.18,264.7,840.18,259.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26S850.5,262.6,850.5,259.63Z"
            fill="#2d2926"
          />
          <path
            d="M865.17,252.13a8.36,8.36,0,0,1,4,.78v3.28h-.3a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C857.1,255.1,860,252.13,865.17,252.13Z"
            fill="#2d2926"
          />
          <path
            d="M885.15,259.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C883.08,252.13,885.15,255.13,885.15,259.06Zm-9.42-.69h4.95c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M887.13,266.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,887.13,266.38Z"
            fill="#2d2926"
          />
          <path
            d="M901.14,266.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,901.14,266.38Z"
            fill="#2d2926"
          />
          <path
            d="M929.22,259.06q0,.94-.12,1.86h-9.23c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C927.15,252.13,929.22,255.13,929.22,259.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3S920,256,919.8,258.37Z"
            fill="#2d2926"
          />
          <path
            d="M931.2,266.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,931.2,266.38Z"
            fill="#2d2926"
          />
          <path
            d="M960.87,265.54h-.12a5.46,5.46,0,0,1-4.23,1.68c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66h-.33V253a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.4Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28h-1.5c-1.86,0-2.82.54-2.82,1.8a1.66,1.66,0,0,0,1.64,1.7.88.88,0,0,0,.28,0Z"
            fill="#2d2926"
          />
          <path
            d="M968.87,252.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V267h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M995.43,265.6h-.12a5,5,0,0,1-3.93,1.62c-3.54,0-5.94-2.79-5.94-7.2,0-5,3.18-7.86,7.92-7.86a7.48,7.48,0,0,1,2,.27V245H1000v21.9h-4.56Zm-2.46-2a3.23,3.23,0,0,0,2.37-1v-7a3.84,3.84,0,0,0-1.5-.24c-2.16,0-3.54,1.41-3.54,4.41,0,2.65,1.11,3.85,2.67,3.85Z"
            fill="#2d2926"
          />
          <path
            d="M811.35,305.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,811.35,305.38Z"
            fill="#2d2926"
          />
          <path
            d="M833.22,291.13a8.36,8.36,0,0,1,4,.78v3.28h-.35a6,6,0,0,0-3.09-.66c-2.7,0-3.78,1.23-3.78,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.33,9.33,0,0,1-4.56.9c-5.07,0-7.8-2.64-7.8-7.29C825.15,294.1,828,291.13,833.22,291.13Z"
            fill="#2d2926"
          />
          <path
            d="M848,304.54h-.14a5.46,5.46,0,0,1-4.23,1.68c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66H840V292a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63H848Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28h-1.5c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7A1,1,0,0,0,845.37,303.25Z"
            fill="#2d2926"
          />
          <path
            d="M855.93,301.75V284h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C857.16,306.1,855.93,304.72,855.93,301.75Z"
            fill="#2d2926"
          />
          <path
            d="M864.66,286.57a2.62,2.62,0,1,1,2.8,2.43h-.19C865.65,289,864.66,288.19,864.66,286.57Zm.3,4.86h4.65v14.49H865Z"
            fill="#2d2926"
          />
          <path
            d="M873.15,291.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V306h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M889.23,309.43c0-1.5,1.56-2.79,3.21-3.24V306a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.41,4.41,0,0,1,2.07,3.9c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C891.42,313,889.23,311.68,889.23,309.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.79,0,4.35-.65,4.35-1.82ZM899,296.25a2.29,2.29,0,0,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52s2.28-.83,2.28-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M914.25,301.87v-7h-2.19v-3.42h2.19v-3.87h4.62v3.87h3.66v3.42h-3.66v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C915.81,306.19,914.25,304.9,914.25,301.87Z"
            fill="#2d2926"
          />
          <path
            d="M925.05,284h4.65v8.85h.17a6.29,6.29,0,0,1,4.53-1.74c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V306H925.1Z"
            fill="#2d2926"
          />
          <path
            d="M956,298.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C953.87,291.13,956,294.13,956,298.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3s-2.54.63-2.78,3Z"
            fill="#2d2926"
          />
          <path
            d="M965.43,291.43H970v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c2.19,0,3.63.75,4.29,2.25a6.65,6.65,0,0,1,5.1-2.25c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.1-2.13a4.26,4.26,0,0,0-2.64,1V306h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V306h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M991.41,298.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S991.41,303.7,991.41,298.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38S997.11,303,999,303,1001.73,301.6,1001.73,298.63Z"
            fill="#2d2926"
          />
          <path
            d="M1018.32,304.6h-.12a5,5,0,0,1-3.93,1.59c-3.54,0-5.94-2.79-5.94-7.2,0-5,3.18-7.86,7.92-7.86a7.48,7.48,0,0,1,2,.27V284h4.62v21.9h-4.56Zm-2.46-2a3.23,3.23,0,0,0,2.37-1v-7a3.84,3.84,0,0,0-1.5-.24c-2.16,0-3.54,1.41-3.54,4.41,0,2.65,1.11,3.85,2.68,3.85Z"
            fill="#2d2926"
          />
          <path
            d="M1039.77,298.06q0,.94-.12,1.86h-9.24c.36,2.07,1.62,2.88,4.29,2.88a9.37,9.37,0,0,0,3.84-.72h.33v3.24a12.5,12.5,0,0,1-5.16.9c-5.73,0-8.22-2.73-8.22-7.41s2.76-7.68,7.74-7.68C1037.7,291.13,1039.77,294.13,1039.77,298.06Zm-9.42-.69h5c-.09-1.95-.75-3-2.22-3s-2.49.63-2.73,3Z"
            fill="#2d2926"
          />
          <path
            d="M1042.44,301.75V284h4.62v17.31c0,1,.45,1.38,1.23,1.38a2.94,2.94,0,0,0,.93-.12h.3v3.24a11.19,11.19,0,0,1-2.94.27C1043.67,306.1,1042.44,304.72,1042.44,301.75Z"
            fill="#2d2926"
          />
        </g>
        <g>
          <path
            d="M1096.87,207.52h6.87c6.09,0,11.28,2.64,11.28,9.69s-4.74,10.71-11.55,10.71h-6.6Zm6.25,16.71c4.08,0,6.77-1.65,6.77-6.72s-2.69-6.3-6.74-6.3h-1.41v13Z"
            fill="#2d2926"
          />
          <path
            d="M1117.87,213.43h4.65v1.76h.09a5.25,5.25,0,0,1,4-1.92,4.9,4.9,0,0,1,1.08.12v3.69h-.33a5.32,5.32,0,0,0-4.86,1.41v9.42h-4.63Z"
            fill="#2d2926"
          />
          <path
            d="M1129.59,208.57a2.62,2.62,0,1,1,2.8,2.43h-.19C1130.58,211,1129.59,210.19,1129.59,208.57Zm.3,4.86h4.65v14.49h-4.67Z"
            fill="#2d2926"
          />
          <path
            d="M1136.28,213.43h4.8l2,6.24c.21.63.81,2.76,1,3.57h.06c.15-.81.72-2.94.93-3.57l2-6.24h4.47l-5,14.49h-5.13Z"
            fill="#2d2926"
          />
          <path
            d="M1153.17,208.57A2.62,2.62,0,1,1,1156,211h-.19C1154.16,211,1153.17,210.19,1153.17,208.57Zm.3,4.86h4.65v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1161.66,213.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41H1171v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1.05V228h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1177.74,231.43c0-1.5,1.56-2.79,3.21-3.24V228a2.22,2.22,0,0,1-1.53-2.16,3.44,3.44,0,0,1,2.25-2.94v-.09a4.58,4.58,0,0,1-3.27-4.53c0-3.21,2.46-5.19,6.81-5.19a11.78,11.78,0,0,1,2.58.27,4.11,4.11,0,0,1,4.32-3.48h1v3.21h-.84a2.53,2.53,0,0,0-2.43,1.17,4.41,4.41,0,0,1,2.07,3.9c0,3.39-2.49,5.25-6.72,5.25h-.9a1.3,1.3,0,0,0-.81,1c0,.54.39.78,1.32.84l3.06.21c3.63.21,5.64,1.59,5.64,4.14,0,3.42-3.09,5.34-9.15,5.34C1179.93,235,1177.74,233.68,1177.74,231.43Zm11.67-1c0-.9-.66-1.26-2.28-1.32l-3.6-.21a2.12,2.12,0,0,0-1.65,1.83c0,1,1.11,1.56,3.15,1.56,2.84,0,4.38-.65,4.38-1.82Zm-2-12.18a2.29,2.29,0,0,0-4.56-.42,2.94,2.94,0,0,0,0,.42c0,1.62.84,2.52,2.28,2.52a2.18,2.18,0,0,0,2.33-2.48Z"
            fill="#2d2926"
          />
          <path
            d="M1103.67,252.13a8.24,8.24,0,0,1,4,.78v3.28h-.32a6,6,0,0,0-3.09-.66c-2.7,0-3.79,1.23-3.79,4.2s1.35,4.11,4,4.11a6.84,6.84,0,0,0,3.18-.66h.33v3.18a9.29,9.29,0,0,1-4.55.9c-5.08,0-7.8-2.64-7.8-7.29C1095.61,255.1,1098.45,252.13,1103.67,252.13Z"
            fill="#2d2926"
          />
          <path
            d="M1109.37,259.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S1109.37,264.7,1109.37,259.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26S1119.69,262.6,1119.69,259.63Z"
            fill="#2d2926"
          />
          <path
            d="M1127.19,252.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V267h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1145.4,262.87v-7h-2.19v-3.42h2.19v-3.87h4.65v3.87h3.66v3.42h-3.66v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C1147,267.19,1145.4,265.9,1145.4,262.87Z"
            fill="#2d2926"
          />
          <path
            d="M1155.87,247.57a2.62,2.62,0,1,1,2.8,2.43h-.19C1156.87,250,1155.87,249.19,1155.87,247.57Zm.3,4.86h4.7v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1164.39,252.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V267h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1181.87,262.84V252.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08V252.43h4.61v14.49h-4.56v-1.5h-.12a6.52,6.52,0,0,1-4.65,1.8C1183.5,267.19,1181.87,265.72,1181.87,262.84Z"
            fill="#2d2926"
          />
          <path
            d="M1198.53,259.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S1198.53,264.7,1198.53,259.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26,2.72-1.38,2.72-4.35Z"
            fill="#2d2926"
          />
          <path
            d="M1216.29,262.84V252.43h4.65v9c0,1.56.69,2.13,2.07,2.13a4,4,0,0,0,2.67-1.08V252.43h4.65v14.49h-4.56v-1.5h-.12a6.52,6.52,0,0,1-4.65,1.8C1217.94,267.19,1216.29,265.72,1216.29,262.84Z"
            fill="#2d2926"
          />
          <path
            d="M1233.18,266.38v-3.33h.3a12.36,12.36,0,0,0,4.77.93c2,0,2.55-.39,2.55-1.23s-.81-1.05-2.43-1.35c-3.48-.6-5.28-1.83-5.28-4.71s2.07-4.56,6.75-4.56a11.83,11.83,0,0,1,4.92.84v3.22h-.33a11,11,0,0,0-4.38-.9c-2.1,0-2.55.42-2.55,1s.51,1.05,2.55,1.44c3.39.63,5.22,1.74,5.22,4.56,0,3.24-2.67,4.86-6.78,4.86A13,13,0,0,1,1233.18,266.38Z"
            fill="#2d2926"
          />
          <path
            d="M1096.2,286.57a2.61,2.61,0,0,1,5.22,0,2.29,2.29,0,0,1-2.12,2.45,1.93,1.93,0,0,1-.48,0C1097.2,289,1096.2,288.19,1096.2,286.57Zm.3,4.86h4.66v14.49h-4.66Z"
            fill="#2d2926"
          />
          <path
            d="M1104.69,291.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V306h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1122.21,291.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V306h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1138.87,298.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S1138.87,303.7,1138.87,298.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26,2.66-1.38,2.66-4.35Z"
            fill="#2d2926"
          />
          <path
            d="M1154.7,291.43h4.8l2,6.24c.21.63.81,2.76,1,3.57h.06c.15-.81.72-2.94.93-3.57l2-6.24H1170l-5,14.49h-5.13Z"
            fill="#2d2926"
          />
          <path
            d="M1179.66,304.54h-.12a5.46,5.46,0,0,1-4.23,1.68c-3,0-4.77-1.83-4.77-4.35,0-3.09,2.25-4.62,6.87-4.62h2v-.48c0-1.62-.63-2.22-3.09-2.22a11.76,11.76,0,0,0-4.32.66h-.33V292a16.18,16.18,0,0,1,5.82-.84c5.07,0,6.57,1.56,6.57,5.16v9.63h-4.44Zm-2.64-1.29a3,3,0,0,0,2.43-1.2v-2.28H1178c-1.86,0-2.82.54-2.82,1.8a1.67,1.67,0,0,0,1.65,1.7A1,1,0,0,0,1177,303.25Z"
            fill="#2d2926"
          />
          <path
            d="M1188.27,301.87v-7h-2.19v-3.42h2.19v-3.87h4.65v3.87h3.66v3.42h-3.66v5.76c0,1.65.45,2.16,1.8,2.16a4.43,4.43,0,0,0,1.53-.21h.33v3.21a10.43,10.43,0,0,1-3.51.45C1189.87,306.19,1188.27,304.9,1188.27,301.87Z"
            fill="#2d2926"
          />
          <path
            d="M1198.77,286.57a2.62,2.62,0,1,1,2.8,2.43h-.19C1199.76,289,1198.77,288.19,1198.77,286.57Zm.3,4.86h4.65v14.49h-4.65Z"
            fill="#2d2926"
          />
          <path
            d="M1206.36,298.78c0-4.68,2.73-7.65,7.62-7.65s7.56,2.49,7.56,7.44c0,4.68-2.73,7.65-7.59,7.65S1206.36,303.7,1206.36,298.78Zm10.32-.15c0-2.94-.81-4.29-2.73-4.29s-2.73,1.41-2.73,4.38.84,4.26,2.76,4.26,2.7-1.38,2.7-4.35Z"
            fill="#2d2926"
          />
          <path
            d="M1224.18,291.43h4.53v1.5h.12a6.61,6.61,0,0,1,4.65-1.8c3.06,0,4.71,1.47,4.71,4.38v10.41h-4.65v-9c0-1.56-.69-2.13-2.07-2.13a4.15,4.15,0,0,0-2.64,1V306h-4.65Z"
            fill="#2d2926"
          />
        </g>
      </g>
      <g id="links">
        <NavLink exact to={props.menuItems[2].path}>
          <rect dataname="link-1" width="198" height="357.86" opacity="0" />
        </NavLink>
        <NavLink exact to={props.menuItems[3].path}>
          <rect
            dataname="link-4"
            x="246"
            width="198"
            height="357.86"
            opacity="0"
          />
        </NavLink>
        <NavLink exact to={props.menuItems[4].path}>
          <rect
            dataname="link-3"
            x="506.26"
            width="198"
            height="357.86"
            opacity="0"
          />
        </NavLink>
        <NavLink exact to={props.menuItems[5].path}>
          <rect
            dataname="link-2"
            x="769.67"
            width="262.76"
            height="357.86"
            opacity="0"
          />
        </NavLink>
        <NavLink exact to={props.menuItems[6].path}>
          <rect
            dataname="link-5"
            x="1055.84"
            width="180.16"
            height="357.86"
            opacity="0"
          />
        </NavLink>
      </g>
    </svg>
  );
};

export default connect(state => ({
  menuItems: state.menu,
  location: state.router.location,
}))(IntroNumbersGraphicBlock);
