import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components/macro';
import Radial from '../Radial';
import C from '../Container';
import MarkdownParser from '../MarkdownParser';
import { Paragraph } from '../Text';

const Block = styled.div`
  width: 100%;
  margin: 2em 0;
  padding-bottom: ${props => (props.isLastItem ? '2em' : 0)};

  @media ${props => props.theme.breakpoints.small} {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

const Container = styled(C)`
  /* display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0; */
  margin: 0 auto;
`;

const RadialOuter = styled.div`
  width: 100%;
  margin-bottom: 0.5em;
  max-width: 10em;

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    max-width: 8em;
  }
`;

const IntroStyled = styled.div`
  margin: 0;

  ${Paragraph} {
    font-family: Brando Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.5;
  }
`;

const RadialGraph = ({ fields, theme, ...rest }) => {
  const { number, caption } = fields;
  return (
    <Block isLastItem={rest.index + 1 === rest.numberOfBlocks}>
      <Container>
        <RadialOuter>
          <Radial
            data={String(number)}
            barColor={theme.sectionHighlight || theme.colors.blue}
            trackColor={theme.colors.cloud}
          />
        </RadialOuter>
        <IntroStyled>
          <MarkdownParser>{caption}</MarkdownParser>
        </IntroStyled>
      </Container>
    </Block>
  );
};

RadialGraph.propTypes = {
  fields: PropTypes.shape({
    caption: PropTypes.string,
    number: PropTypes.number,
  }).isRequired,
};

export default withTheme(RadialGraph);
