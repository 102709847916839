import styled from 'styled-components/macro';
import { ReactComponent as Logo } from '../assets/logo.svg';

/**
 * The logo, which usually just goes in the header. This could easily go inside
 * the Header component, but this keeps it a little easier to read
 */

// export default styled.img``;

/** SVGs are already compiled as react components, so just do this */

export default styled(Logo)`
  display: block;
  margin: 1em;
  width: 14rem;
  max-width: 100%;
  height: auto;
`;

/** If we wanted to export an actual image we could do this: */
// export default () => <Image src={Logo} alt="Logo" />;
