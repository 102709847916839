import React, { /*useEffect,*/ useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { textInputs, buttons, transparentize, transitions } from 'polished';
// import { useForm } from 'react-hook-form';
import { Paragraph } from './Text';
import { ButtonStyles } from './Links';
import { ReactComponent as Spinner } from '../assets/spinner.svg';
import config from '../config';

const spin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

const SpinnerStyled = styled(Spinner)`
  animation: ${spin} 1s linear infinite;
  width: 5em;
  height: 5em;
  display: block;
  margin: 2em auto;
  text-align: center;
  color: ${props => props.theme.colors.blue};
`;

const FormContainer = styled.div`
  padding: 1em 0 0;
`;

const Form = styled.form`
  ${textInputs()}, select {
    border: 1px solid ${props => transparentize(0.75, props.theme.colors.black)};
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.9;
    height: 3.4rem;
    padding: 0.25rem 1rem;
    font-variant-numeric: lining-nums;
    ${props =>
      transitions(
        ['border-color', 'background-color'],
        `${props.theme.animations.duration} ${props.theme.animations.easing}`
      )};

    &:focus,
    &:active {
      outline: none;
      border-color: ${props => props.theme.colors.green2};
      background-color: ${props =>
        transparentize(0.95, props.theme.colors.green2)};
    }
  }

  ${buttons()} {
    ${ButtonStyles};
    margin: 2em auto 1em !important;
  }
`;

const Label = styled.label`
  display: flex;
  flex-flow: column;
  margin: 0.75em auto;
`;

const Input = styled.input``;

const Select = styled.select``;

const EloquaFormBlock = props => {
  // const { register, handleSubmit } = useForm();
  const [redirectUrl, setRedirectUrl] = useState(window.location.href);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // fetch('https://s998125501.t.eloqua.com/e/f2', {
  //   method: 'POST',
  //   mode: 'no-cors',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   referrerPolicy: 'no-referrer',
  //   body: JSON.stringify(e),
  // })
  //   .then(response => {
  //     props.handleGateDisable();
  //     return response.text();
  //   })
  //   .then(resStr => {
  //     const data = resStr === '' ? {} : JSON.parse(resStr);
  //     // console.log({data});
  //     return data;
  //   })
  //   .catch(console.error);
  //   return;
  // };

  const onFormChangeHandler = () => {
    const url = new URL(window.location);
    const bypassParamString = `${config.bypassGateParam}=1`;

    /**
     * If there's an existing search string, tack the redirect param on the end.
     * If not, add the redirect as the only search param
     */
    const updatedUrlSearch = !!url.search.length
      ? `${url.search}&${bypassParamString}`
      : `?${bypassParamString}`;

    /**
     * If there's a search string, replace the original with the updated one.
     * If not, rebuild the url with the gate bypass param
     */
    const redirectUrl = url.search.length
      ? url.href.replace(url.search, updatedUrlSearch)
      : `${url.origin}${url.pathname}${updatedUrlSearch}${url.hash}`;

    return setRedirectUrl(redirectUrl);
  };

  const onFormSubmitHandler = () => setIsSubmitting(true);

  return (
    <FormContainer>
      <Form
        // onSubmit={handleSubmit(handleFormSubmit)}
        method="POST"
        name="InsperityDownloadForm"
        action="https://s998125501.t.eloqua.com/e/f2"
        onChange={() => onFormChangeHandler()}
        onSubmit={() => onFormSubmitHandler()}
      >
        <Paragraph>
          Fill out this short form to keep reading{' '}
          <em>The Five Challenges of Sustainable Growth</em>.
        </Paragraph>

        <Label htmlFor="name1">
          First Name*
          <Input
            // ref={register}
            type="text"
            id="name1"
            // name="name1"
            name="FirstName"
            required
          />
        </Label>

        <Label htmlFor="name2">
          Last Name*
          <Input
            // ref={register}
            type="text"
            id="name2"
            // name="name2"
            name="LastName"
            required
          />
        </Label>

        <Label htmlFor="email">
          Email*
          <Input
            // ref={register}
            type="email"
            id="email"
            // name="email"
            name="EmailAddress"
            required
          />
        </Label>

        <Label htmlFor="company">
          Company*
          <Input
            // ref={register}
            type="text"
            id="company"
            name="company"
            required
          />
        </Label>

        {/* <Label htmlFor="RoleOther">
          <Input
            // ref={register}
            type="text"
            id="RoleOther"
            name="RoleOther"
            required
          />
        </Label> */}

        <Label htmlFor="RoleOther">
          Role*
          <Select id="RoleOther" name="RoleOther" required>
            <option value="" selected="selected" disabled>
              --
            </option>
            <option value="Accounting">Accounting</option>
            <option value="Administrative">Administrative</option>
            <option value="Business Owner">Business Owner</option>
            <option value="C-level">C-level</option>
            <option value="Finance">Finance</option>
            <option value="Human Resources">Human Resources</option>
            <option value="Legal">Legal</option>
            <option value="Other">Other</option>
          </Select>
        </Label>

        <Label htmlFor="ZipCode">
          Zip Code*
          <Input
            // ref={register}
            type="text"
            id="ZipCode"
            name="ZipCode"
            required
          />
        </Label>

        <Input
          // ref={register}
          type="hidden"
          name="elqFormName"
          value="InsperityDownloadForm"
        />
        <Input
          // ref={register}
          type="hidden"
          name="elqSiteID"
          value="998125501"
        />
        <Input
          // ref={register}
          type="hidden"
          name="elqCustomerGUID"
        />
        <Input
          // ref={register}
          type="hidden"
          name="elqCookieWrite"
          value="0"
        />
        <Input
          // ref={register}
          type="hidden"
          // name="FROM"
          name="pid"
          value="Starr-Lightpaper"
        />
        <Input
          // ref={register}
          type="hidden"
          // name="SiebelCampaignID"
          name="cid"
          value="300000207056453"
        />
        <Input
          // ref={register}
          // type="text"
          type="hidden"
          name="URL"
          value={redirectUrl}
        />
        {isSubmitting && <SpinnerStyled />}
        <Input
          // ref={register}
          disabled={isSubmitting}
          type="submit"
          name="Submit"
          value="Submit"
        />
      </Form>
      <script type="text/javascript">
        {`
        <!--//
          var timerId = null, timeout = 5;
          //-->
          `}
      </script>
      <script type="text/javascript">
        {`<!--//
            function WaitUntilCustomerGUIDIsRetrieved() {
              if (!!(timerId)) {
                if (timeout == 0) {
                  return;
                }
              if (typeof this.GetElqCustomerGUID === 'function') {
                document.forms["InsperityDownloadForm"].elements["elqCustomerGUID"].value = GetElqCustomerGUID();
                return;
              }
              timeout -= 1;
            }
            timerId = setTimeout("WaitUntilCustomerGUIDIsRetrieved()", 500);
            return;
            }
            window.onload = WaitUntilCustomerGUIDIsRetrieved;
            _elqQ.push(['elqGetCustomerGUID']);
          //-->`}
      </script>
    </FormContainer>
  );
};

export default EloquaFormBlock;
