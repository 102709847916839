import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import { Paragraph } from '../Text';

const CalloutBlock = styled.div`
  /* margin: 2em; */
  font-size: 1.2em;
  font-weight: 700;
  margin-bottom: 0.83333em;

  /* @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    margin: 2em 0;
  } */
`;

const StyledContainer = styled(Container)`
  padding: 0.5em 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 66%;
  }
`;

const QuoteContainer = styled.div`
  ${Paragraph} {
    font-family: ${props => props.theme.fonts.serif};
    position: relative;

    &:before {
      position: absolute;
      display: block;
      font-family: inherit;
      font-size: 1em;
      content: '"';
      left: -0.45em;
      top: 0.2em;
      line-height: 1.5;
    }
  }
`;

const AttributionContainer = styled.div`
  width: calc(100% - 1em);
  margin-left: 1em;

  /* @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {

  } */
`;

const AttributionParagraph = styled(Paragraph)`
  font-size: 1.2rem;
`;
const AttributionName = styled(AttributionParagraph)`
  margin-bottom: 0;
  font-weight: 600;
`;
const AttributionTitle = styled(AttributionParagraph)`
  margin-bottom: 0;
`;
const AttributionCompany = styled.div`
  ${Paragraph} {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
`;

/** The most basic type of block we can render */
const Callout = ({ fields, ...rest }) => {
  const { content, quoteAttribution } = fields;
  const isQuote = !!quoteAttribution;

  return (
    <CalloutBlock>
      <StyledContainer size="slim">
        {isQuote ? (
          <QuoteContainer>
            <MarkdownParser>
              {/**
               * If this is a quote, remove any quotes so that the layout can
               * handle the quotation display
               */}
              {content.replace(/^["|“]/gi, '')}
            </MarkdownParser>
          </QuoteContainer>
        ) : (
          <MarkdownParser>{content}</MarkdownParser>
        )}
        {!!quoteAttribution && !!quoteAttribution.fields && (
          <AttributionContainer>
            {!!quoteAttribution.fields.name && (
              <AttributionName>{quoteAttribution.fields.name}</AttributionName>
            )}
            {!!quoteAttribution.fields.title && (
              <AttributionTitle>
                {quoteAttribution.fields.title}
              </AttributionTitle>
            )}
            {!!quoteAttribution.fields.company && (
              <AttributionCompany>
                <MarkdownParser>
                  {quoteAttribution.fields.company}
                </MarkdownParser>
              </AttributionCompany>
            )}
          </AttributionContainer>
        )}
      </StyledContainer>
    </CalloutBlock>
  );
};

Callout.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    content: PropTypes.string.isRequired,
  }).isRequired,
};

export default Callout;
