import Hero from '../components/blocks/Hero';
import Default from '../components/blocks/Default';
import Radial from '../components/blocks/Radial';
import Grid from '../components/blocks/Grid';
import Poll from '../components/blocks/Poll';
import Callout from '../components/blocks/Callout';
import Media from '../components/blocks/Media';
import MediaText from '../components/blocks/MediaText';

/**
 * Define default blocks available for sections, matching the content type's
 * machine name and the actual component name. If you have a content type
 * defined in the content (remote or local), it must have a block associated
 * with it or it won't display.
 *
 * For example, if you add a custom callout content type, you'll need a Callout
 * component, and it needs to be defined here as { callout: Callout }
 */
export default {
  hero: Hero,
  content: Default,
  radialChart: Radial,
  grid: Grid,
  poll: Poll,
  callout: Callout,
  media: Media,
  mediaText: MediaText,
};
