import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import { transitions } from 'polished';
import { useTrail, animated } from 'react-spring';
import { toCamelCase } from '../../utilities';
import MarkdownParser from '../MarkdownParser';
import Container from '../Container';
import Waypoint from '../Waypoint';
import { ReactComponent as HeroMaskTopSvg } from '../../assets/hero-mask-top.svg';
import { ReactComponent as HeroMaskBottomSvg } from '../../assets/hero-mask-bottom.svg';

export const HeroStyled = styled.div`
  /* margin-top: 8rem; */
  display: flex;
  flex-flow: columnn;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  /* height: 60vh; */
  min-height: 88rem;
  position: relative;
  color: ${props =>
    props.textColor === 'dark'
      ? props.theme.colors.black
      : props.theme.colors.white};
  overflow: hidden;
  margin: -2em auto 1em;
  /* Allow other content to flow over the top and bottom masks: */
  z-index: -1;

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    min-height: 96rem;
    margin-top: ${props => props.layout !== 'cover' && '1em'};
    margin-bottom: 1em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    min-height: 70rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    min-height: auto;
    padding: ${props => (props.layout === 'cover' ? '8em 0 4em' : '8em 0')};
  }
`;

const HeroBackgroundImageLayer = styled(
  ({ imageIndex, backgroundImageUrl, ...rest }) => <animated.div {...rest} />
)`
  height: ${props => (props.imageIndex === 0 ? '200vh' : '120%')};
  height: 300vh;
  min-height: 120em;
  position: absolute;
  // top: -10%;
  top: -10vh;
  left: 0;
  // width: 200rem;
  min-width: 100%;
  z-index: -1;
  background-image: ${props => `url(${props.backgroundImageUrl})`};
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-repeat: no-repeat repeat;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    background-size: 140%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    background-size: 260vw;
  }
`;

const BackgroundImageLayer = styled(HeroBackgroundImageLayer)`
  height: 200vh;
  bottom: 0;
  right: 0;
  background-size: cover !important;
  background-attachment: fixed;
  background-repeat: no-repeat;
`;

const ForegroundImageLayer = styled(HeroBackgroundImageLayer)`
  background-repeat: no-repeat;
  top: 0;
  background-position: center 80vh;
  height: 100vh;

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    background-size: 115%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    background-size: 130%;
  }
`;

const MaskSvgStyles = css`
  color: ${props => props.theme.colors.white};
  position: absolute;
  width: 100%;
  min-width: 2000px;
  height: auto;
  left: 50%;
  transform: translateX(-40%);

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    width: 100%;
    left: 0;
    transform: none;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    min-width: ${props => props.theme.breakpoints.xlarge};
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    min-width: ${props => props.theme.breakpoints.large};

    ${props =>
      props.layout === 'cover' &&
      css`
        margin-bottom: -2em;
      `}
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    min-width: ${props => props.theme.breakpoints.medium};
    transform: translateX(-60%);
  }
`;

const TopMaskSvg = styled(HeroMaskTopSvg)`
  top: -0.5em;
  ${MaskSvgStyles};
`;

const BottomMaskSvg = styled(HeroMaskBottomSvg)`
  bottom: -0.5em;
  ${MaskSvgStyles};
`;

const HeroContainer = styled(Container)`
  padding-left: 1em;
  padding-right: 1em;
  transform: ${props =>
    props.isVisible ? `translateY(0)` : `translateY(2em)`};
  opacity: ${props => (props.isVisible ? `1` : `0`)};
  ${props =>
    transitions(
      ['opacity', 'transform'],
      `${props.theme.animations.durationFade} ${props.theme.animations.easing}`
    )};

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
    padding-right: ${props => (props.layout === 'cover' ? '16%' : null)};
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    padding-top: 10em;
    padding-bottom: 10em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.large}) {
    padding-top: 8em;
    padding-bottom: 8em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    max-width: 80%;
    padding-top: 6em;
    padding-bottom: 6em;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    padding: 4em 0;
  }
`;

const Hero = props => {
  const {
    body,
    backgroundImages,
    hideHeroMask = '',
    layout = 'sectionTitle',
    textColor = 'light',
  } = props.fields;
  const ref = useRef(null);
  // const listenerRefSet = useRef(null);
  const [shouldAnimate, setAnimate] = useState(false);
  const [shouldAnimateIn, setShouldAnimateIn] = useState(false);

  const [trail, set] = useTrail(
    backgroundImages && backgroundImages.length ? backgroundImages.length : 0,
    () => ({
      offset: 0,
      config: { mass: 5, tension: 2000, friction: 200 },
    })
  );

  /**
   * useCallback persists this callback function through rerenders, so that we
   * can reference it again later to remove it
   */
  // eslint-disable-next-line
  const handleScrollCallback = useCallback(() => {
    if (!shouldAnimate) {
      return;
    }

    const posY = ref.current.getBoundingClientRect().top;
    set({ offset: posY });
  });

  /**
   * Handle creating the event listeners. This needs to rerun any time the hero
   * ref changes, and we are removing the scroll event listener if the hero is
   * not in view.
   *
   * NOTE: if `useEffect` returns a function, that function is run as the
   * cleanup when the component dismounts. This function is effectively the
   * functional version of `componentDidUnmount`.
   *
   * https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
   */
  useEffect(() => {
    if (shouldAnimate) {
      window.addEventListener('scroll', handleScrollCallback);
    }

    return function cleanup() {
      window.removeEventListener('scroll', handleScrollCallback);
    };
    // eslint-disable-next-line
  }, [handleScrollCallback, shouldAnimate, ref.current]);

  const displayTopMask =
    !hideHeroMask.match(/top/gi) && !hideHeroMask.match(/both/gi);
  const displayBottomMask =
    !hideHeroMask.match(/bottom/gi) && !hideHeroMask.match(/both/gi);

  return (
    <Waypoint
      topOffset="30%"
      bottomOffset="30%"
      onEnter={() => {
        setAnimate(true);
        !shouldAnimateIn && setShouldAnimateIn(true);
      }}
      onLeave={() => setAnimate(false)}
    >
      <HeroStyled
        ref={ref}
        textColor={toCamelCase(textColor)}
        layout={toCamelCase(layout)}
      >
        {displayTopMask && <TopMaskSvg preserveAspectRatio="" />}
        {trail &&
          trail.map((trailProps, index) => {
            const isFirstImage = index === 0;
            const isLastImage = index >= backgroundImages.length - 1;

            const backgroundImage = backgroundImages[index];
            const backgroundImageUrl =
              backgroundImage &&
              backgroundImage.fields &&
              backgroundImage.fields.image &&
              backgroundImage.fields.image.fields &&
              backgroundImage.fields.image.fields.file &&
              backgroundImage.fields.image.fields.file.url;

            return isFirstImage && backgroundImageUrl ? (
              <BackgroundImageLayer
                key={backgroundImage.sys.id}
                backgroundImageUrl={backgroundImageUrl}
              />
            ) : !isLastImage ? (
              backgroundImageUrl && (
                <HeroBackgroundImageLayer
                  key={backgroundImage.sys.id}
                  backgroundImageUrl={backgroundImageUrl}
                  style={{
                    transform: trailProps.offset.interpolate(
                      o => `translateY(calc(${o * 0.015}vh - 30vh))`
                    ),
                  }}
                />
              )
            ) : (
              backgroundImageUrl && (
                <ForegroundImageLayer
                  key={backgroundImage.sys.id}
                  backgroundImageUrl={backgroundImageUrl}
                  style={{
                    transform: trailProps.offset.interpolate(
                      o => `translateY(calc(${o * -0.02}vh - 60vh))`
                    ),
                  }}
                />
              )
            );
          })}
        <HeroContainer
          isVisible={shouldAnimateIn}
          size={toCamelCase(layout) === 'cover' ? 'wide' : null}
          layout={toCamelCase(layout)}
        >
          <MarkdownParser>{body}</MarkdownParser>
        </HeroContainer>
        {displayBottomMask && <BottomMaskSvg layout={toCamelCase(layout)} />}
      </HeroStyled>
    </Waypoint>
  );
};

Hero.propTypes = {
  fields: PropTypes.shape({
    /** Body is the only required child of fields */
    body: PropTypes.string.isRequired,
    backgroundImages: PropTypes.arrayOf(
      PropTypes.shape({
        fields: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      })
    ),
  }).isRequired,
};

export default Hero;
