import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Icon from '../Icon';
import { useSpring, animated } from 'react-spring';
import useMeasure from './useMeasure';
import { H3 } from '../Heading';
import { ReactComponent as ArrowIcon } from './arrow.svg';

const CollapsibleContainer = styled.div`
  margin-bottom: 1em;
`;

const CollapsibleHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eee;
  padding: 0 1em;
  cursor: pointer;
  user-select: none;
  position: relative;
  z-index: 2;

  &:hover {
    background: #ddd;
  }
`;

const CollapsedText = animated(styled.div`
  border: 2px solid #eee;
  background: #fefefe;
  overflow: hidden;
  user-select: none;
  position: relative;
  z-index: 1;
`);

const CollapsedPadding = styled.div`
  padding: 1em;
`;

const CollapsibleIcon = styled(Icon)`
  width: 1em;
  transition: transform ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing};
`;

const CollapsibleContent = props => {
  const [open, toggle] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [bind, { height }] = useMeasure();

  const spring = useSpring({
    opacity: open ? 1 : 0,
    /**
     * maxHeight is being used here temporarily until we can figure out how to
     * use useMeasure to get the height of a hidden element and animate the
     * height instead. Overall, this animation could be improved with some work.
     */
    maxHeight: open ? 2000 : 0,
    x: open ? 0 : -20,
  });

  return (
    <CollapsibleContainer>
      <CollapsibleHeading onClick={() => toggle(!open)}>
        <H3>{props.heading}</H3>
        <CollapsibleIcon
          style={{
            transform: open ? 'rotate(180deg)' : 'none',
          }}
          inline
          icon={ArrowIcon}
        />
      </CollapsibleHeading>
      <CollapsedText
        {...bind}
        style={{
          height: open ? 'auto' : 0,
          opacity: spring.opacity,
          maxHeight: spring.maxHeight,
          transform: spring.x.interpolate(x => `translate3d(0,${x}px,0)`),
        }}
      >
        <CollapsedPadding>{props.children}</CollapsedPadding>
      </CollapsedText>
    </CollapsibleContainer>
  );
};

CollapsibleContent.propTypes = {
  /** The hidden content within the component */
  children: PropTypes.node.isRequired,
  /** Text to use for the heading */
  heading: PropTypes.string.isRequired,
  /**
   * Whether to chain this collapsible conent with others around it and close
   * all of them except the one just opened.
   * TODO: This is not enabled yet
   */
  accordion: PropTypes.bool,
};

CollapsibleContent.defaultProps = {
  heading: '',
  accordion: false,
};

export default CollapsibleContent;
