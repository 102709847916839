import styled, { css } from 'styled-components/macro';

const ParagraphStyles = css`
  font-family: ${props => props.theme.fonts.sansSerif};
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9;
  margin: 0 0 1.25em;
  font-style: normal;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

const ListStyles = css`
  ${ParagraphStyles};

  ol,
  ul {
    font-size: 1em;
  }
`;

/** Set up default text block elements */
export const Paragraph = styled.p`
  ${ParagraphStyles};
`;

export const IntroParagraph = styled(Paragraph)`
  font-size: 2.2rem;
`;

export const HeroIntro = styled(Paragraph)`
  font-weight: 500;
  font-size: 3.4rem;
  color: ${props => props.theme.colors.blue4};

  @media screen and (max-width: ${props => props.theme.breakpoints.medium}) {
    font-size: 2.8rem;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.small}) {
    font-size: 2.4rem;
  }
`;

export const Eyebrow = styled(Paragraph)`
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.3;
  text-transform: uppercase;
  margin-bottom: 0.5em;
`;

export const Span = styled.span``;

export const OL = styled.ol`
  ${ListStyles};
`;

export const UL = styled.ul`
  ${ListStyles};
`;

export const LI = styled.li``;

export const Strong = styled.strong`
  font-weight: 500;
`;
