/** A place to put miscellaneous config options */
export default {
  shouldUseRemoteContent: true,
  contentful: {
    space: 'fhsldcdl3kor',
    accessToken: '58iZcHPyFTyokOtKFdyIL--nREum4NMftfoyiXGIw2A',
  },
  isPaged: false,
  isHeaderUnderFirstBlock: false,
  /** 'dot' or 'full' */
  nav: 'dot',
  twitterHandle: 'insperity',
  /** ?return=1 */
  bypassGateParam: 'return',
};
