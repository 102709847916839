import React from 'react';
import Container from '../Container';
import MediaText from '../MediaText';

export default props =>
  !!props.isNested ? (
    <MediaText {...props} />
  ) : (
    <Container>
      <MediaText {...props} />
    </Container>
  );
