import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Waypoint } from 'react-waypoint';
// import { createCookie } from '../../utilities';
import { disableGate } from '../../logic/actions';
import EloquaForm from '../EloquaForm';

/**
 * You might need to change this based on how much space to put between it and
 * the top of its parent Section. If the lightpaper is paged, then the waypoint
 * will fire on a gated Section before this takes effect
 */
const gateOffset = '30em';

/**
 * Sets the position and dimensions of the gate, but doesn't actually center it
 * vertically with the window. See the comment over WaypointStyled for more info
 */
const GateWrapper = styled.div`
  position: ${props => (props.isStuck ? 'fixed' : 'absolute')};
  z-index: 100000;
  top: ${props => (props.isStuck ? '50%' : gateOffset)};
  left: 50%;
  transform: translateX(-50%);
`;

/**
 * Sets the actual styles of the gate, and positions it to be center of the
 * screen. Use this for theming
 */
const GateInner = styled.div`
  padding: 0.25em 2em;
  font-size: 0.75em;
  background: white;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  transform: translateY(-50%);
  width: 80vw;
  max-width: 30em;
  height: 100%;
  max-height: 90vh;
  overflow: auto;
  border-radius: 4px;
`;

/**
 * Triggers the waypoint by matching the position of the top of the Gate. It has
 * to match the top instead of the middle (like DotNavigation) because we don't
 * know the Gate's height, the Gate doesn't have a predictable percentage offset
 * based on its parent Section, and rendering it inside the Gate would cause it
 * to become position: fixed, too, which would break it
 */
const WaypointStyled = styled.span`
  position: absolute;
  z-index: -1;
  top: ${gateOffset};
`;

/**
 * We currently need to map innerRef to a real ref to make react-waypoint work
 * with styled-components v4
 */
export const WaypointTrigger = ({ innerRef, ...props }) => (
  <WaypointStyled ref={innerRef} {...props} />
);

const Gate = props => {
  const [isStuck, setStuck] = useState(false);

  return (
    <>
      <Waypoint
        topOffset="50%"
        onPositionChange={event => setStuck(event.currentPosition === 'above')}
      >
        <WaypointTrigger />
      </Waypoint>
      <GateWrapper isStuck={isStuck}>
        <GateInner>
          {/** Replace this with whatever third-party form you're using */}
          <EloquaForm handleGateDisable={props.disableGate} />
        </GateInner>
      </GateWrapper>
    </>
  );
};

const mapDispatchToProps = { disableGate };

export default connect(null, mapDispatchToProps)(Gate);
