/** Define common colors, fonts, and other branding here */
const colors = {
  white: '#fff',
  black: '#2D2926',
  blue: '#004C97',
  blue2: '#00A3E0',
  blue3: '#0072CE',
  blue4: '#59CBE8',
  green: '#84BD00',
  green2: '#009639',
  green3: '#9EA700',
  green4: '#215732',
  red: '#FF3222',
  cloud: '#E2DEDC',
};

/** Light theme for each section */
const light = {
  primary: colors.black,
  secondary: colors.white,
  link: colors.black,
  linkHover: colors.black,
  tweet: colors.black,
  button: colors.black,
  buttonText: colors.white,
};

const sectionThemes = [
  {
    highlight: colors.blue3,
  },
  {
    highlight: colors.blue,
  },
  {
    highlight: colors.green,
  },
  {
    highlight: colors.blue4,
  },
  {
    highlight: colors.green2,
  },
  {
    highlight: colors.blue3,
  },
  {
    highlight: colors.green,
  },
];

const fonts = {
  serif: '"Roboto Slab", serif',
  sansSerif: '"Brando Sans", sans-serif',
};

const breakpoints = {
  small: '35em',
  medium: '64em',
  large: '96em',
  xlarge: '110em',
};

const animations = {
  duration: '0.5s',
  easing: 'cubic-bezier(0.46, 0.06, 0.14, 0.93)',
  durationFade: '1s',
};

const theme = {
  colors,
  fonts,
  breakpoints,
  animations,
  light,
  sectionThemes,
};

export default theme;
